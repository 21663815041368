import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  localeDe, localeEs,
  MbscCalendarEvent,
  MbscCalendarEventData, MbscCellClickEvent, MbscEventcalendar,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscEventUpdatedEvent, MbscEventUpdateEvent, MbscPageChangeEvent, MbscPageLoadedEvent, MbscPageLoadingEvent,
  MbscResource,
  momentTimezone, updateRecurringEvent
} from '@mobiscroll/angular';
import {ApiService} from '../../services/api.service';
import {Absent, Company, Object, PublicHoliday, Task, User, WorkLog} from '../../../../../database-models';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {EditTaskComponent, ModalData as EditTaskModalData} from '../../shared/components/task/edit-task/edit-task.component';

// @ts-ignore
import moment from 'moment-timezone';
import {EventcalendarBase} from '@mobiscroll/angular/dist/js/core/components/eventcalendar/eventcalendar';
import {ActivatedRoute, Router} from '@angular/router';
import {CalendarSettingsComponent} from '../calendar-settings/calendar-settings.component';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {CalendarConfig} from '../../shared/interfaces/calendar-config';
import {GlobalSearchComponent, ModalData as GlobalSearchModalData} from '../../shared/components/global-search/global-search/global-search.component';
import {environment} from '../../../environments/environment';
import {TaskImportComponent} from '../task-import/task-import.component';
import {NzNotificationRef, NzNotificationService} from 'ng-zorro-antd/notification';
import {NzContextMenuService, NzDropdownMenuComponent} from 'ng-zorro-antd/dropdown';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {DatePipe} from '@angular/common';
import {
  WasteplanSchedulerListComponent
} from '../../shared/components/wasteplan/wasteplan-scheduler-list/wasteplan-scheduler-list.component';
import {WorklogService} from '../../shared/services/worklog.service';
import {NzSelectComponent} from 'ng-zorro-antd/select';
import {NzCheckboxComponent} from 'ng-zorro-antd/checkbox';
import _ from 'lodash';

momentTimezone.moment = moment;

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  public backendURL = environment.apiURL;
  public doc = document;

  @ViewChild('notificationTemplate', {static: false})
  private notificationTemplate: TemplateRef<any>;

  @ViewChild('mbscEventcalendar', {static: false})
  private mbscEventcalendar: MbscEventcalendar;

  @ViewChild('wasteplanSchedulerListComponent', {static: false})
  public wasteplanSchedulerListComponent: WasteplanSchedulerListComponent;

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private message: NzMessageService,
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    private notificationService: NzNotificationService,
    private router: Router,
    private worklogService: WorklogService,
    private nzContextMenuService: NzContextMenuService,
    private datePipe: DatePipe,
  ) {

    route.paramMap.subscribe(params => {
      if (params.get('modalMode') === 'new') {
        this.editTask();
      }
    });
  }

  public isLoading = false;
  public showFilter = false;
  public showBacklog = false;
  public checked = false;
  public hidebtn = false;
  public checkedTeam = false;
  public indeterminate = true;
  public indeterminateTeam = true;
  public holidays: PublicHoliday[];
  public userAbsents: Absent[];

  public users: User;
  public company: Company;

  public momentPlugin = momentTimezone;

  public myEvents: MbscCalendarEvent[] | Task[] = [];
  public backlog: MbscCalendarEvent[] | Task[] = [];
  public myUserResourcesAvailable: MbscResource[];
  public myTeamResourcesAvailable: MbscResource[] = [];
  public myUserResources: MbscResource[] = [];
  public myTeamResources: MbscResource[] = [];
  public myResourcesAvailable: MbscResource[] = [];
  public myResources: MbscResource[] = [];

  public savedNotificationDuration = 9000;


  public timeCellSteps: any[] = [
    {
      timeCellStep: 5,
      timeLabelStep: 5,
      dragTimeStep: 5
    },
    {
      timeCellStep: 15,
      timeLabelStep: 15,
      dragTimeStep: 5
    },
    {
      timeCellStep: 30,
      timeLabelStep: 30,
      dragTimeStep: 15
    },
    {
      timeCellStep: 60,
      timeLabelStep: 60,
      dragTimeStep: 15
    },
    {
      timeCellStep: 120,
      timeLabelStep: 60,
      dragTimeStep: 15
    }
  ];
  public timeCallStepsActive = 1;
  public view = 'day';
  public config: CalendarConfig = {
    startDay: 1,
    endDay: 0,
    startTime: moment('1900-01-01 00:00').format(),
    endTime: moment('1900-01-01 00:00').format(),
    employeeCanEdit: false
  } as CalendarConfig;

  public settings: any = {
    notificationMode: 'ask'
  };

  public calView: MbscEventcalendarView;


  public eventSettings: MbscEventcalendarOptions = {
    locale: localeDe,
    exclusiveEndDates: false,
    returnFormat: 'iso8601',
    timezonePlugin: this.momentPlugin,
    dataTimezone: 'utc',
    displayTimezone: 'local',
    theme: 'ios',
    themeVariant: 'light',
    width: '100%',
    clickToCreate: 'double',
    dragToCreate: true,
    dragToMove: true,
    dragToResize: true,
    externalDrop: true,
    onEventCreated: (args) => {
     setTimeout(() => {
        //console.log('Created:', args);
        args.event.backlog = false;
        if (args.action === 'externalDrop') {

          if (args.event.children?.length > 0) {
            for (let event of args.event.children) {
              event.start = args.event.start;
              event.end = args.event.end;
              event.resource = args.event.resource;
              event.timezone = args.event.timezone;
              this.changeTaskDirect(event);
            }
          } else {
            this.changeTaskDirect(args.event);
          }
        } else {
          this.newTaskDirect(args.event);
        }
      });
    },
    onEventClick: (args) => {
      if (args.event?.original) {
        this.editTask(args.event.original, false, args.event.original);
      } else {
        this.editTask(args.event, false, args.event);
      }
    },
    onEventUpdate: (args: MbscEventUpdateEvent, inst: EventcalendarBase) => {
      console.log(args)
      if (args.resourceObj.type !== args.oldResourceObj.type) {
        if (Array.isArray(args.event.resource) && args.event.resource.length > 1) {
          this.message.error('Verschieben auf Team nicht möglich, da mehr als ein Mitarbeiter zugewiesen ist!')
          return false;
        }
      }
      return true;
    },
    onEventUpdated: (args: MbscEventUpdatedEvent, inst: EventcalendarBase) => {
      //console.log(args);

      this.changeTaskDirect(args.event);
    },
    onCellDoubleClick: (event: MbscCellClickEvent, inst: EventcalendarBase) => {
      //console.log(event.date);
      if (this.view === 'month') {
        this.view = 'day';
        this.changeView();
        inst.navigate(event.date);
      }
    },
    colors: [
      {
        allDay: true,
        background: 'rgb(241,241,241)',
        recurring: {
          repeat: 'weekly',
          weekDays: 'SA,SU'
        },
      }
    ]
  };

  public recurringModal(): Promise<'all' | 'current' | 'following'> {
    return new Promise<'all' |'current' | 'following'>(async (resolve, reject) => {
      const modal: NzModalRef = this.modalService.create({
        nzTitle: 'Wiederkehrende Aufgabe bearbeiten',
        nzContent: 'Sie bearbeiten eine wiederkehrende Aufgabe. Welche Ereignisse sollen bearbeitet werden?',
        nzWidth: '600px',
        nzOnCancel: () => {
          reject();
        },
        nzFooter: [
          {
            label: 'Dieses Ereignis',
            onClick(): void {
              resolve('current');
              modal.destroy('current');
            }
          },
          {
            label: 'Dieses und Zukünftige',
            onClick(): void {
              resolve('following');
              modal.destroy('following');
            }
          },
          {
            label: 'Alle Ereignisse',
            onClick(): void {
              resolve('all');
              modal.destroy('all');
            }
          }
        ]
      });


    });

  }

  public newEventData() {
    return {
      title: 'Neue Aufgabe',
      backlog: false,
      timezone: 'Europe/Berlin'
    };
  }

  public changeNotificationMode(mode: string): void {
    this.settings.notificationMode = mode;
    localStorage.setItem('tasks.settings', JSON.stringify(this.settings));
  }

  async ngOnInit(): Promise<void> {

    let user = this.authenticationService.currentUserValue;
    this.users = user;
    if (user.company) {
      this.company = user.company;
    }
    if (user.company?.calendarConfig) {
      this.config = user.company.calendarConfig;
    }
    this.getPublicHolidays();

    const view = localStorage.getItem('tasks.view');
    const settings = localStorage.getItem('tasks.settings');
    if (view) {
      this.view = view;
    }
    if (settings) {
      this.settings = JSON.parse(settings);
    }

    const timeView = localStorage.getItem('timeView');
    if (timeView) {
      this.timeCallStepsActive = Number(timeView);
    }

    Promise.all([this.getUserResources(), this.getTeamResources()]).then(res => {
      //console.log('da', res);
      if (res[0] && res[1]) {
        if (this.myTeamResources?.length <= 0 && this.myResources?.length <= 0) {
          this.showFilter = true;
          this.checkAllUser();
        }
        this.changeView();
      }
    });

    this.getBacklog();
  }

  public filter: any = {
    reference: [],
    userIds: [],
    teamIds: []
  };

  private calendarRange = {
    start: null,
    end: null
  };

  public setCalendarRange(event: MbscPageChangeEvent | MbscPageLoadingEvent): void {
    this.calendarRange.start = event.firstDay.toISOString();
    this.calendarRange.end = event.lastDay.toISOString();
    this.getAllTasks();
  }

  public getAllTasks(): void {
    if (!this.users.isManager) {
      this.getMyTasks();
    } else {
      this.filter.userIds = this.myUserResources.map(user => user.id);
      this.filter.teamIds = this.myTeamResources.map(team => team.id);

      let filters: any = {
        objectIds: [],
        customerIds: []
      };

      if (this.filter.reference) {
        for (let ref of this.filter.reference) {
          if (ref.member === 'Object') {
            filters.objectIds.push(ref.id);
          }
          if (ref.member === 'Contact') {
            filters.customerIds.push(ref.id);
          }
        }
      }

      this.filter.objectIds = JSON.stringify(filters.objectIds);
      this.filter.customerIds = JSON.stringify(filters.customerIds);
    }

    let tmpFlter = {...this.filter};

    tmpFlter.start = this.calendarRange.start;
    tmpFlter.end = this.calendarRange.end;


    //console.log(tmpFlter);
    this.isLoading = true;
    this.api.getFilteredTask(tmpFlter).subscribe((tasks: Task[]) => {
      this.myEvents = tasks;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
    localStorage.setItem('tasks.filters', JSON.stringify(this.filter));
  }

  public getMyTasks(): void {
    this.api.getMyTask().subscribe((tasks: Task[]) => {
      this.myEvents = tasks;
    });
  }

  public getBacklog(): void {
    this.wasteplanSchedulerListComponent?.getWastePlans();
    this.isLoading = true;
    this.api.getTask('backlog').subscribe((tasks: Task[]) => {
      this.isLoading = false;
      this.backlog = [];
      for (const bl of tasks) {
        bl.start = '08:00';
        bl.end = '09:00';
        bl.resource = undefined;
        this.backlog.push(bl);
      }
    }, error => {
      this.isLoading = false;
    });
  }

  public async getUserResources(): Promise<boolean> {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.api.getTaskUserResources().subscribe((userResources: any[]) => {
        this.isLoading = false;
        this.myUserResourcesAvailable = userResources;
        this.restoreFilters();
        this.updateSingleChecked();
        resolve(true);
      }, error => {
        this.isLoading = false;
        reject();
      });
    });
  }

  public async getTeamResources(): Promise<boolean> {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      this.api.getTaskTeamResources().subscribe((teamResources: any[]) => {
        this.isLoading = false;
        this.myTeamResourcesAvailable = teamResources;
        this.restoreFilters();
        this.updateSingleTeamChecked();
        resolve(true);
      }, error => {
        this.isLoading = false;
        reject();
      });
    });
  }

  private addAbsentsToView(userResources: User | any = []): void {
    let absents: Absent[] = [];

    for (let user of userResources) {
      absents = [...absents, ...user.absents];
    }

    for (let absent of absents) {
      let dates = [];
      while (moment(absent.start) <= moment(absent.end)) {
        dates.push(moment(absent.start).format('YYYY-MM-DD'));
        absent.start = moment(absent.start).add(1, 'days').format('YYYY-MM-DD');
      }

      for (let day of dates) {
        this.eventSettings.colors.push({
          start: moment(day).startOf('day').format(),
          end: moment(day).endOf('day').format(),
          text: absent.approved ? ((absent.halfDay ? '1/2 ' : '√ ') + absent.absentType.name) : (absent.halfDay ? '1/2 ' : '') + absent.absentType.name + ' (nicht bestätigt)',
          resource: absent.userId,
          background: absent.absentType.lightcolor,
          cssClass: absent.approved ? '' : 'absent-stripes-bg',
          cellCssClass: absent.approved ? '' : 'absent-stripes-bg'
        });
      }
    }
  }


  public editTask(
    task?: Task | MbscCalendarEvent,
    isTempId: boolean = false,
    oldTask?: Task | MbscCalendarEvent): void {
    //console.log(task);
    const editTaskModal = this.modalService.create<EditTaskComponent, EditTaskModalData>({
      nzContent: EditTaskComponent,
      nzData: {
        task: task,
        isTempId: isTempId
      },
      nzWidth: '1100px',
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzOnCancel: instance => {
        //console.log(instance);

        this.getBacklog();
        this.getAllTasks();
      },
      nzOnOk: instance => {
        if (isTempId) {
          // @ts-ignore
          this.myEvents = [...this.myEvents, instance.task];
        } else {

          // Deleted
          if (instance.taskDeleted || instance.task.backlog) {
            // @ts-ignore
            this.myEvents = this.myEvents.filter(item => {
              if (item && item.id !== instance.task.id) {
                return item;
              }
            });
          } else {
            // Changed

            // @ts-ignore
            this.myEvents = this.myEvents.filter(item => {
              if (item && item.id !== instance.task.id) {
                return item;
              }
            });
            // @ts-ignore
            this.myEvents = [...this.myEvents, instance.task];

            this.openNotificationQuestion(instance.task);
          }
        }


        this.getBacklog();
        this.getAllTasks();
      }
    });
    editTaskModal.afterClose.subscribe(res => {
      this.route.paramMap.subscribe(params => {
        if (params.get('modalMode') === 'new') {
          this.router.navigate(['tasks']);
        }
      });
    });
  }

  public newTaskDirect(event: MbscCalendarEvent, open: boolean = true): void {
    let task: Task | MbscCalendarEvent = {} as Task;
    task = event;
    task.id = undefined;
    task = this.transformResource(task);

    this.isLoading = true;
    this.api.setTask(task).subscribe((taskReturn: Task) => {
      this.isLoading = false;
      this.message.success('Erfolgreich erstellt');
      task.id = taskReturn.id;
      task.worklogneeded = taskReturn.worklogneeded;
      // @ts-ignore
      this.myEvents = this.myEvents.filter(item => item.id !== event.id);
      // @ts-ignore
      this.myEvents = [...this.myEvents, task];
      if (open) {
        this.editTask(task);
      }
    }, error => {
      this.isLoading = false;
    });
  }

  private transformResource(task: Task | MbscCalendarEvent): Task | MbscCalendarEvent {
    const fullResource = this.myResourcesAvailable.find(res => res.id === task.resource);
    if (fullResource?.type === 'team') {
      task.users = [];
      task.team_id = task.resource;
    } else {
      task.team_id = null;
      task.users = this.myUserResourcesAvailable.filter(resource => {
        return typeof task.resource === 'string' ? task.resource === resource.id : task.resource.includes(resource.id)
      });
    }
    return task;
  }

  public changeTaskDirect(task: Task | MbscCalendarEvent, openNotificationQuestion = true): void {
    //console.log(task);
    task = this.transformResource(task);
    this.isLoading = true;
    this.api.setTask(task).subscribe((taskReturn: Task) => {
      this.isLoading = false;


      if (taskReturn.wasteplan?.id) {

      }
      // returnTask.resource = returnTask.user_id;

      // @ts-ignore
      this.myEvents = this.myEvents.filter(item => item.id !== task.id);
      // @ts-ignore
      this.myEvents = [...this.myEvents, task];

      if (openNotificationQuestion) {
        this.openNotificationQuestion(Object.assign({}, task));
      }

      this.getBacklog();
    }, error => {
      this.isLoading = false;
      this.getBacklog();
    });
  }

  private openNotificationQuestion(returnTask: Task | any): void {

    if (this.settings.notificationMode === 'always') {
      this.sendTaskNotification(returnTask, undefined, true);
    } else if (this.settings.notificationMode === 'ask') {
      returnTask._countdown = this.savedNotificationDuration / 1000;
      const interval = setInterval(() => {
        returnTask._countdown--;
        if (returnTask._countdown <= 0) {
          clearInterval(interval);
        }
      }, 1000);
      this.notificationService.template(this.notificationTemplate, {
        nzData: returnTask, nzDuration: this.savedNotificationDuration, nzPlacement: 'bottomLeft', nzPauseOnHover: false
      });
    }

  }

  public increaseView(): void {
    if (this.timeCallStepsActive < this.timeCellSteps.length) {
      this.timeCallStepsActive++;
    }
    this.changeView();
    localStorage.setItem('timeView', String(this.timeCallStepsActive));
  }

  public decreaseView(): void {
    if (this.timeCallStepsActive > 0) {
      this.timeCallStepsActive--;
    }
    this.changeView();
    localStorage.setItem('timeView', String(this.timeCallStepsActive));
  }

  public changeView(): void {
    setTimeout(() => {
      localStorage.setItem('tasks.view', this.view);
      switch (this.view) {
        case 'month':
          this.hidebtn = true;
          this.calView = {
            calendar: {weekNumbers: true, labels: true},
          };
          break;
        case 'week':
          this.hidebtn = false;
          this.calView = {
            schedule: {
              type: 'week',
              maxEventStack: 'auto',
              allDay: true,
              timeLabelStep: this.timeCellSteps[this.timeCallStepsActive].timeLabelStep,
              timeCellStep: this.timeCellSteps[this.timeCallStepsActive].timeCellStep,
              startDay: this.config.startDay,
              endDay: this.config.endDay,
              startTime: this.config.startTime,
              endTime: this.config.endTime
            },
          };
          break;
        case 'day':
          this.hidebtn = false;
          this.calView = {
            calendar: {type: 'week', weekNumbers: true, scroll: 'horizontal'},
            schedule: {
              type: 'day',
              maxEventStack: 'auto',
              allDay: true,
              timeLabelStep: this.timeCellSteps[this.timeCallStepsActive].timeLabelStep,
              timeCellStep: this.timeCellSteps[this.timeCallStepsActive].timeCellStep,
              startTime: this.config.startTime,
              endTime: this.config.endTime
            }
          };
          break;
        case 'agenda':
          this.hidebtn = true;
          this.calView = {
            calendar: {type: 'week', weekNumbers: true, scroll: 'horizontal'},
            agenda: {type: 'week'}
          };
          break;
        case 'timeline':
          this.hidebtn = false;
          this.calView = {
            timeline: {
              weekNumbers: true,
              type: 'day',
              timeLabelStep: this.timeCellSteps[this.timeCallStepsActive].timeLabelStep,
              timeCellStep: this.timeCellSteps[this.timeCallStepsActive].timeCellStep,
              startTime: this.config.startTime,
              endTime: this.config.endTime
            }
          };
          break;
      }
    });
  }

  public filtersClosed(): void {
    this.showFilter = false;
    this.myUserResources = this.myUserResourcesAvailable.filter(value => value.checked);
    this.addAbsentsToView(this.myUserResources);
    this.myTeamResources = this.myTeamResourcesAvailable.filter(value => value.checked);
    this.myResources = [];
    this.myResources = this.myUserResources.concat(this.myTeamResources);
    this.saveFilters();
    this.getAllTasks();
  }

  private saveFilters(): void {
    localStorage.setItem('myUserResources', JSON.stringify(this.myUserResources));
    localStorage.setItem('myTeamResources', JSON.stringify(this.myTeamResources));
  }

  private restoreFilters(): void {
    const filter = JSON.parse(localStorage.getItem('tasks.filters'));
    if (filter) {
      this.filter = filter;
    }
    try {
      const restoreUserResources = JSON.parse(localStorage.getItem('myUserResources'));
      const restoreTeamResources = JSON.parse(localStorage.getItem('myTeamResources'));
      if (restoreUserResources) {
        for (const user of restoreUserResources) {

          this.myUserResourcesAvailable?.map(value => {
            if (value.id === user.id) {
              value.checked = true;
            }
          });
        }
        this.myUserResources = this.myUserResourcesAvailable?.filter(value => value.checked);
      } else {
        this.myUserResourcesAvailable?.map(value => {
          value.checked = true;
        });
        this.myUserResources = this.myUserResourcesAvailable;
      }
      if (restoreTeamResources) {
        for (const team of restoreTeamResources) {

          this.myTeamResourcesAvailable.map(value => {
            if (value.id === team.id) {
              value.checked = true;
            }
          });
        }
        this.myTeamResources = this.myTeamResourcesAvailable.filter(value => value.checked);
      } else {
        this.myTeamResourcesAvailable.map(value => {
          value.checked = true;
        });
        this.myUserResources = this.myTeamResourcesAvailable;
      }
      this.addAbsentsToView(this.myUserResources);
      this.myResources = [];
      this.myResources = this.myUserResources?.concat(this.myTeamResources);
      this.myResourcesAvailable = this.myUserResourcesAvailable?.concat(this.myTeamResourcesAvailable);
    } catch (e) {
      console.log(e);
    }
  }

  updateSingleChecked(): void {
    if (this.myUserResourcesAvailable.every(item => !item.checked)) {
      this.checked = false;
      this.indeterminate = false;
    } else if (this.myUserResourcesAvailable.every(item => item.checked)) {
      this.checked = true;
      this.indeterminate = false;
    } else {
      this.indeterminate = true;
    }
  }

  public checkAllUser(): void {
    this.indeterminate = false;
    this.myUserResourcesAvailable.map(user => {
      user.checked = this.checked;
    });
  }

  updateSingleTeamChecked(): void {
    if (this.myTeamResourcesAvailable.every(item => !item.checked)) {
      this.checkedTeam = false;
      this.indeterminateTeam = false;
    } else if (this.myTeamResourcesAvailable.every(item => item.checked)) {
      this.checkedTeam = true;
      this.indeterminateTeam = false;
    } else {
      this.indeterminateTeam = true;
    }
  }

  public checkAllTeam(): void {
    this.indeterminateTeam = false;
    this.myTeamResourcesAvailable.map(team => {
      team.checked = this.checkedTeam;
    });
  }

  public calendarSetting(): void {
    const newObjectModal = this.modalService.create<CalendarSettingsComponent>({
      nzContent: CalendarSettingsComponent,
      nzData: {},
      nzWidth: '550px',
      nzFooter: null
    });
    newObjectModal.afterClose.subscribe((data: any) => {
      this.getAllTasks();
    });
  }

  public globalSearch(): void {
    const newObjectModal = this.modalService.create<GlobalSearchComponent, GlobalSearchModalData>({
      nzContent: GlobalSearchComponent,
      nzData: {
        hideLinkTaskBtn: false,
        hideLinkTicketBtn: false,
        showInfotxt: false,
        showSearchFilter: false,
        searchPlaceholder: 'Aufgaben Suchen',
        searchCategories: 'TASKS'
      },
      nzWidth: '1000px',
      nzFooter: null,
      nzCloseIcon: ''
    });
    newObjectModal.afterClose.subscribe((data: any) => {
      this.getAllTasks();
    });
  }

  public getPublicHolidays(): void {
    this.api.getPublicHolidays(this.company?.bundeslandId).subscribe(holidays => {
      this.holidays = holidays;
      for (let holiday of this.holidays) {
        this.eventSettings.colors.push({
          date: moment(holiday.date).format(),
          title: holiday.name,
          background: 'rgb(255,200,200)',
        });
      }
    }, error => {
      console.error('Failed');
    });
  }

  public sendTaskNotification(task: Task, notification: any, successMsg: boolean = false): void {
    this.api.sendTaskNotification(task).subscribe(res => {
      if (successMsg) {
        this.notificationService.success('Benutzer benachrichtigt', 'Benachrichtigung wurde gesendet');
      }

      if (notification) {
        notification.close();
      }
    });
  }

  @ViewChild('fullScreenElement') fullScreenElement;

  public fullScreen(): void {
    const elem = this.fullScreenElement.nativeElement;
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    } else {
      document.exitFullscreen();
      if (elem.exitFullscreen) {
        elem.exitFullscreen();

      } else if (elem.mozCancelFullScreen) {
        /* Firefox */
        elem.mozCancelFullScreen();
      } else if (elem.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitExitFullscreen();
      } else if (elem.msExitFullscreen) {
        /* IE/Edge */
        elem.msExitFullscreen();
      }
    }

  }

  public taskImport(): void {
    const modal = this.modalService.create<TaskImportComponent>({
      nzContent: TaskImportComponent,
      nzData: {},
      nzFooter: null,
      nzWidth: 1300
    });
    modal.afterClose.subscribe(value => {
      this.getAllTasks();
    });
  }

  public recurringDone(ev: MbscCalendarEventData): boolean {
    try {

      let date;
      // @ts-ignore
      if (ev.startDate?.m) {
        // @ts-ignore
        date = ev.startDate?.m.format('YYYY-MM-DD');
      } else {
        date = moment(ev.startDate).format('YYYY-MM-DD');
      }
      const checked = ev?.original.recurringDone?.includes(date);
      return checked;
    } catch (e) {

    }
  }

  public setTaskDone(ev: MbscCalendarEventData | MbscCalendarEvent, done: boolean, self?: NzCheckboxComponent): void {
    let date;
    if (ev.startDate?.m) {
      date = ev.startDate?.m.format('YYYY-MM-DD');
    } else {
      date = moment(ev.startDate).format('YYYY-MM-DD');
    }
    let event;

    // true if recurring, false if not recurring
    if (ev?.original.original) {
      event = ev.original.original;
    } else {
      event = ev?.original;
    }


    if (done) {
      if (!event.recurringDone) {
        event.recurringDone = [];
      }
      if (event.worklogneeded) {


        this.api.getTaskWorklogs(event.id).subscribe((worklogs: WorkLog[]) => {
          let found = worklogs.filter(wl => moment(wl.start).format('YYYY-MM-DD') === date);
          //console.log(found);
          if (found.length <= 0) {
            this.worklogService.newWorklog({
              task: event,
              object: event.object,
              room: event.room,
              ticket: event.ticket,
              contact: event.contact
            } as WorkLog).then(res => {
              event.recurringDone.push(date);
              this.changeTaskDirect(event, false);
            }, reason => {
              this.message.warning('Diese Aufgabe kann nicht erledigt werden, da ein Arbeitsprotokoll verpflichtend ist.');
              self.nzChecked = false;
              self.blur();
            });
          } else {
            event.recurringDone.push(date);
            this.changeTaskDirect(event, false);
          }
        });
      } else {
        event.recurringDone.push(date);
        this.changeTaskDirect(event, false);
      }
    } else {
      event.recurringDone = event.recurringDone.filter(e => e !== date);
      this.changeTaskDirect(event, false);
    }

  }

  public printPDF(): void {
    let optionalStart;
    if (this.view === 'day' || this.view === 'timeline') {
      optionalStart = this.mbscEventcalendar._calendarView.activeDate;
    }

    const sortedEvents = this.mbscEventcalendar.getEvents(optionalStart).sort((a, b) => {
      // @ts-ignore
      return new Date(a.start) - new Date(b.start);
    });

    //console.log(sortedEvents);

    let header = ['Zeitpunkt', 'Aufgabe', 'Objekt / Kontakt'];
    if (this.myUserResources.length !== 1 || this.myTeamResources?.length > 0) {
      header.push('Mitarbeiter / Team');
    }
    let exportJSON: any[] = [];

    for (const event of sortedEvents) {
      const row = [
        {
          content: (event.allDay ? moment(event.start).format('DD.MM.YYYY') : moment(event.start).format('DD.MM.YYYY HH:mm')) + '\n' +
            (event.allDay ? moment(event.end).format('DD.MM.YYYY') : moment(event.end).format('DD.MM.YYYY HH:mm')),
          styles: {
            fontSize: 8
          },
          colSpan: 1
        },
        {
          content: (event.title),
          colSpan: 1,
          styles: {
            fontSize: 9,
          }
        },
        {
          content: (
            event.object ? (event.object?.name + (event.room ? ' (' + event.room?.name + ')' : '')) : (event.contact
              ? (event.contact.name)
              : '-')
          ),
          colSpan: 1,
          styles: {
            fontSize: 8
          }
        }
      ];

      if (this.myUserResources.length !== 1 || this.myTeamResources?.length > 0) {
        row.push(event.user?.name || event.team?.name);
      }
      exportJSON.push(row);
    }


    let doc = new jsPDF();

    if (this.myUserResources.length === 1 && this.myTeamResources?.length <= 0) {
      doc.text('Aufgabenliste von ' + this.myUserResources[0].name, 15, 16);
      doc.setFontSize(12);
    } else {
      doc.text('Aufgabenliste', 15, 16);
      doc.setFontSize(12);
    }


    autoTable(doc, {
      head: [header],
      body: exportJSON,
      margin: {top: 25},
      headStyles: {
        fillColor: [255, 143, 0]
      }
    });

    doc.save('Aufgabenliste.pdf');
  }
}
